import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './vuetify'
import i18n from './i18n'
import './main.styl'

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')

window.snapSaveState = () => {
  document.querySelector("#app").setAttribute("data-server-rendered", "true")
}

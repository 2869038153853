import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import en from 'vuetify/es5/locale/en'
import de from 'vuetify/es5/locale/de'

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg',
    },
    theme: {
        themes: {
            light: {
                primary: '#E96A53',
                secondary: '#8F6B56',
                accent: '#E96A53'
            }
        }
    },
    lang: {
        locales: { en, de },
        current: 'en'
    }
})

<template>
  <v-app id="app">

    <v-app-bar app color="white">
      <div class="inheritHeight">
        <router-link to="/" class="inheritHeight">
          <img class="inheritHeight" src="./assets/logo.svg">
        </router-link>
      </div>

      <v-spacer></v-spacer>

      <LocaleChanger></LocaleChanger>

      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn v-for="(route, i) in $router.options.routes" :key="`TR${i}`" v-if="route.displayOnNav !== false" :to="route.path" text>
          {{ route.name ? item.name : $t(route.nameT) }}
        </v-btn>
      </v-toolbar-items>

      <v-app-bar-nav-icon class="hidden-md-and-up" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

    </v-app-bar>

    <v-navigation-drawer class="hidden-md-and-up" app right v-model="drawer" temporary>
      <v-list>
        <template v-for="(route, i) in $router.options.routes" v-if="route.displayOnNav !== false">
          <v-list-item :key="`NR${i}`" :to="route.path">
            <v-list-item-content>
              <v-list-item-title>{{ route.name ? item.name : $t(route.nameT) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="`D${i}`" v-if="i !== ($router.options.routes.length - 1)"></v-divider>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-slide-y-transition mode="out-in">

        <router-view></router-view>

      </v-slide-y-transition>
    </v-main>

    <v-footer dark color="secondary" height="inherit">
      <v-row no-gutters>
        <v-col cols="12" class="text-center">

          <v-bottom-sheet v-model="sheet">
            <template v-slot:activator="{ on }">
              <v-btn text small v-on="on">Impressum</v-btn>
            </template>

            <div style="background-color: white" class="text-center" @click="sheet = false">

              <h2 class="headline py-4">Angaben gemäß § 5 TMG</h2>
              <p>Tanasoaia Aurelia</p>
              <p>Pension Hexagon</p>
              <p>Lendorfer Straße 16</p>
              <p>Österreich, 9800 Spittal an der Drau</p>

              <h2 class="headline pb-2">Kontakt</h2>
              <v-row>
                <v-col cols="6" class="pa-2 text-right">Telefonnummer</v-col>
                <v-col cols="6" class="pa-2 text-left">+43 650 9423618</v-col>

                <v-col cols="6" class="pa-2 text-right">Email</v-col>
                <v-col cols="6" class="pa-2 text-left">office@pensionhexagon.at</v-col>

                <v-col cols="6" class="pa-2 text-right">Website</v-col>
                <v-col cols="6" class="pa-2 text-left">www.pensionhexagon.at</v-col>
              </v-row>

            </div>

          </v-bottom-sheet>

        </v-col>
        <v-col cols="12" class="text-center text-caption pb-1">
          Copyright &copy; {{ new Date().getFullYear() }} Pension Hexagon
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import LocaleChanger from './components/LocaleChanger'

export default {
  components: {
    LocaleChanger
  },
  data() {
    return {
      drawer: null,
      sheet: false
    }
  }
}
</script>

<style lang="stylus">
#app
  font-family 'PT Sans', sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  color #2c3e50

.inheritHeight
  height inherit

@media only screen and (max-width: 960px)
  .v-toolbar__title
    position absolute
    left 50%
    transform translateX(-50%)

  .v-toolbar__content
    padding-left 0 !important
    .v-menu
      order -1

</style>

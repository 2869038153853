<template>
  <v-container>

    <v-card>
      <v-card-text class="body-1">
        <v-list dense style="overflow-wrap: anywhere;">
          <template v-for="(item, index) in contactInfo">
            <v-list-item :key="index">
              <v-list-item-icon>
                <v-icon color="primary">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ item.data ? item.data : $t(item.dataT) }}
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
      <Map :coords="[46.80758, 13.48086]" :markerColor="this.$vuetify.theme.currentTheme.primary"></Map>
    </v-card>

  </v-container>
</template>

<script>
import Map from '@/components/Map.vue'
import { mdiAccount, mdiPhone, mdiEmail, mdiWeb, mdiMapMarker } from '@mdi/js'

export default {
  name: 'contact',
  components: {
    Map
  },
  data() {
    return {
      contactInfo: [
        {
          icon: mdiAccount,
          data: 'Aurelia'
        },
        {
          icon: mdiPhone,
          data: '+43 650 9423618'
        },
        {
          icon: mdiEmail,
          data: 'office@pensionhexagon.at'
        },
        {
          icon: mdiWeb,
          dataT: 'contact.languages'
        },
        {
          icon: mdiMapMarker,
          dataT: 'contact.address'
        }
      ]
    }
  }
}
</script>

<style lang="stylus">

.v-list__tile__content
  user-select text

</style>

<template>

  <v-container>
    <v-row>

      <v-col cols="12" lg="6" v-html="apartment.bp"></v-col>

      <v-col cols="12" lg="6">
        <v-carousel hide-delimiters :cycle="false" v-model="activeImage">
          <v-carousel-item v-for="(img, i) in apartment.images" :key="i" :src="img"></v-carousel-item>
        </v-carousel>
      </v-col>

    </v-row>
  </v-container>

</template>

<script>
  export default {
    name: 'Apartment',
    props: {
      apartment: Object,
      active: Boolean
    },
    mounted: function() {
      this.$el.querySelectorAll('.bpRoom rect, .bpRoom path').forEach(element =>
        element.addEventListener('click', (e) => {
          const index = parseInt(e.target.id.split('_')[1])
          if (this.activeImage !== index) this.activeImage = index
        })
      )

      this.$el.querySelector(`#${this.apartment.name.toLowerCase()}_${this.activeImage}`).classList.add('active')

      const T = this

      function animate() {
        const now = Date.now()

        if (!T.active) {
          T.resetLast()
        } else if (now - T.last > 4000) {
          T.activeImage = (T.activeImage + 1) % T.apartment.images.length
        }
        requestAnimationFrame(animate)
      }
      requestAnimationFrame(animate)
    },
    data() {
      return {
        last: Date.now(),
        activeImage: this.apartment.first
      }
    },
    methods: {
      resetLast() {
        this.last = Date.now()
      }
    },
    watch: {
      activeImage: function(newIndex, oldIndex) {
        this.resetLast()
        this.$el.querySelector(`#${this.apartment.name.toLowerCase()}_${oldIndex}`).classList.remove('active')
        this.activeImage = newIndex
        this.$el.querySelector(`#${this.apartment.name.toLowerCase()}_${newIndex}`).classList.add('active')
      }
    }
  }
</script>

<style lang="stylus">

.blueprint
  width 100%
  height 100%

.bpOther
  fill: #424242
  pointer-events: none

.bpRoom
  rect
  path
    opacity 0
    fill: #e57373
    fill-opacity: 0.25
    cursor: pointer
    transition: opacity .2s ease-out
    &:hover, &.active
      opacity 1

.bpWindow
  fill: #81d4fa

.bpEntrance
  fill: #fff59d

</style>

<template>
  <div class="full">
    <l-map id="contactMap" ref="map" :zoom="zoom" :minZoom="minZoom" :maxZoom="maxZoom" :center="center" :options="options">
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-marker :lat-lng="marker" :icon="customIcon" @click="markerClick"></l-marker>
    </l-map>
  </div>
</template>

<script>
import L from 'leaflet'
import 'leaflet-fullscreen'
import 'leaflet/dist/leaflet.css'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  props: {
    coords: Array,
    markerColor: String
  },
  data() {
    return {
      zoom: 16,
      minZoom: 4,
      maxZoom: 18,
      center: L.latLng(this.coords),
      marker: L.latLng(this.coords),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="https://openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors',
      customIcon: L.icon({
        iconUrl: encodeURI('data:image/svg+xml,' +
          `<svg fill="${this.markerColor}" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>`
        ).replace('#', '%23'),
        iconSize: [48, 48],
        iconAnchor: [24, 48]
      }),
      options: {
        fullscreenControl: true
      }
    }
  },
  methods: {
    markerClick: function() {
      this.$refs.map.mapObject.setView(L.latLng(this.coords), 16)
    }
  }
}
</script>

<style lang="stylus">

#contactMap
  width 100%
  height 50vh
  .leaflet-control-zoom a
    color black

</style>

<template>

  <v-menu offset-y transition="slide-y-transition">
    <template v-slot:activator="{ on }">
      <v-btn id="lang-btn" text v-on="on">
        <img :src="langs[locale]">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(img, l) in langs" :key="`Lang${l}`" @click="changeLocale(l)">
        <img :src="img">
      </v-list-item>
    </v-list>
  </v-menu>

</template>

<script>
import { mdiMenuDown } from '@mdi/js'

export default {
  name: 'locale-changer',
  data () {
    const langs = {
      en: require('@/assets/lang_en.svg'),
      de: require('@/assets/lang_de.svg')
    }

    const browserLanguage = (navigator.languages
      ? navigator.languages[0]
      : (navigator.language || navigator.userLanguage)
    ).split('-')[0]
    const locale = Object.keys(langs).includes(browserLanguage) ? browserLanguage : 'en'
    this.changeLocale(locale)
    
    return {
      locale,
      langs,
      icon: mdiMenuDown
    }
  },
  methods: {
    changeLocale(val) {
      this.locale = val
      this.$i18n.locale = val
      this.$vuetify.lang.current = val
      document.documentElement.lang = val
    }
  }
}
</script>

<style scoped lang="stylus">

.v-menu
  height inherit
  &__content
    border-radius 0 0 4px 4px

#lang-btn
  height inherit
  margin 0
  padding-right 8px
  min-width 0

img
  width 32px

</style>

<template>
  <v-tabs fixed-tabs v-model="active" background-color="white">
    <v-tab v-for="(apartment, i) in apartments" :key="`T${i}`">{{apartment.name}}</v-tab>

    <v-tabs-items v-model="active" touchless>
      <v-tab-item v-for="(apartment, i) in apartments" :key="`Ti${i}`">
        <Apartment :active="active === i" :apartment="apartment"></Apartment>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import Apartment from '../components/Apartment'

export default {
  components: {
    Apartment,
  },
  data() {
    return {
      active: 1,
      apartments: [
        {
          name: 'Flowers',
          first: 2,
          images: [
            require('@/assets/flowers/0.jpg?vuetify-preload'),
            require('@/assets/flowers/1.jpg?vuetify-preload'),
            require('@/assets/flowers/2.jpg?vuetify-preload'),
            require('@/assets/flowers/3.jpg?vuetify-preload'),
          ],
          bp: `<svg class="blueprint" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1488 837">
              <path class="bpOther" d="M619,91V321H389V746h710V91ZM869,243c37.26.54,67.45,31.17,68,69H869Zm-13,0h11v69H856ZM693,93h83v16H693Zm83,18a84.06,84.06,0,0,1-83,83V111Zm-142,0h57v85h2v0a86.07,86.07,0,0,0,85-85h76V321H634ZM757,336h83a84.06,84.06,0,0,1-83,83Zm0-2V323h83v11Zm-31,85a84.06,84.06,0,0,1-83-83h83Zm0-85H643V323h83ZM568,744H470V728h98Zm166-18H404V336H641a86.07,86.07,0,0,0,85,85v0h2V336h6Zm164,18H800V728h98Zm181-18H749V336h6v85h2v0a86.06,86.06,0,0,0,85-85h237Zm0-405H869v-7h70v-2h0c-.52-38.9-31.61-70.43-70-71V111h210Zm18,224h-16V447h16Zm0-270h-16V177h16Z" />
              <g class="bpRoom">
                <rect id="flowers_3" x="749" y="336" width="330" height="390" />
                <rect id="flowers_2" x="404" y="336" width="330" height="390" />
                <rect id="flowers_1" x="869" y="111" width="210" height="210" />
                <rect id="flowers_0" x="634" y="111" width="220" height="210" />
              </g>
              <g class="bpWindow">
                <rect x="1081" y="447" width="16" height="98" />
                <rect x="1081" y="177" width="16" height="98" />
                <rect x="800" y="728" width="98" height="16" />
                <rect x="470" y="728" width="98" height="16" />
              </g>
              <rect class="bpEntrance" x="693" y="93" width="83" height="16" />
              <g class="bpOther">
                <path d="M953.12,197.19l24.69,24.69a3.75,3.75,0,0,0,5.31,0l1.76-1.76a3.75,3.75,0,0,0,0-5.31h0a17.56,17.56,0,0,0,.53-21.94,12,12,0,0,1,6.45-1.86A12.16,12.16,0,0,1,1004,203.15V251h10V203.15a22.14,22.14,0,0,0-37.13-16.3,17.53,17.53,0,0,0-16.67,3.28h0a3.75,3.75,0,0,0-5.31,0l-1.76,1.76a3.75,3.75,0,0,0,0,5.31ZM954,208.5a2.5,2.5,0,1,0,2.5-2.5A2.5,2.5,0,0,0,954,208.5Zm-5,0a2.5,2.5,0,1,0-2.5,2.5A2.5,2.5,0,0,0,949,208.5Zm-15,0a2.5,2.5,0,1,0,2.5-2.5A2.5,2.5,0,0,0,934,208.5Zm25,5a2.5,2.5,0,1,0,2.5-2.5A2.5,2.5,0,0,0,959,213.5Zm-7.5-2.5a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,951.5,211ZM939,213.5a2.5,2.5,0,1,0,2.5-2.5A2.5,2.5,0,0,0,939,213.5Zm25,5a2.5,2.5,0,1,0,2.5-2.5A2.5,2.5,0,0,0,964,218.5Zm-5,0a2.5,2.5,0,1,0-2.5,2.5A2.5,2.5,0,0,0,959,218.5Zm-15,0a2.5,2.5,0,1,0,2.5-2.5A2.5,2.5,0,0,0,944,218.5Zm20,5a2.5,2.5,0,1,0-2.5,2.5A2.5,2.5,0,0,0,964,223.5Zm-15,0a2.5,2.5,0,1,0,2.5-2.5A2.5,2.5,0,0,0,949,223.5Zm15,5a2.5,2.5,0,1,0,2.5-2.5A2.5,2.5,0,0,0,964,228.5Zm-10,0a2.5,2.5,0,1,0,2.5-2.5A2.5,2.5,0,0,0,954,228.5Zm5,5a2.5,2.5,0,1,0,2.5-2.5A2.5,2.5,0,0,0,959,233.5Zm5,5a2.5,2.5,0,1,0,2.5-2.5A2.5,2.5,0,0,0,964,238.5Z" />
                <path d="M671.5,491h-85a7.5,7.5,0,0,0-7.5,7.5v50a7.5,7.5,0,0,0,7.5,7.5h38.3v5h-25a5,5,0,0,0,0,10h60a5,5,0,0,0,0-10h-25v-5h36.7a7.5,7.5,0,0,0,7.5-7.5v-50A7.5,7.5,0,0,0,671.5,491ZM669,546H589V501h80Z" />
                <path d="M559.42,537.11a4.58,4.58,0,0,1,4.58,4.58v26H545.67V555.44H472.33v12.23H454V498.92a4.59,4.59,0,0,1,4.58-4.59h9.17a4.59,4.59,0,0,1,4.58,4.59v38.19Zm-68.75-36.67a15.28,15.28,0,1,0,15.27,15.28A15.28,15.28,0,0,0,490.67,500.44ZM564,524.89a18.33,18.33,0,0,0-18.33-18.33H513.58a4.58,4.58,0,0,0-4.58,4.58V531h55Z" />
                <path d="M914,496.27c.11.68,2.32,13.63,2.32,18.58,0,7.54-4,12.92-9.94,15.08l1.86,34.34a3.47,3.47,0,0,1-3.46,3.65h-9.23a3.47,3.47,0,0,1-3.46-3.65l1.86-34.34c-5.94-2.16-9.94-7.55-9.94-15.08,0-5,2.21-17.9,2.32-18.58.46-2.93,6.54-3,6.91.16v20.36c.19.49,2.18.46,2.31,0,.2-3.65,1.14-20.07,1.15-20.45.48-3,6.45-3,6.91,0,0,.39.95,16.8,1.15,20.45.13.46,2.14.49,2.31,0V496.43C907.44,493.31,913.52,493.34,914,496.27Zm17.19,41.21L929,564.17a3.46,3.46,0,0,0,3.45,3.75h8.08a3.45,3.45,0,0,0,3.46-3.46V497.54a3.45,3.45,0,0,0-3.46-3.46c-11.9,0-31.93,25.74-9.36,43.4Z" />
              </g>
            </svg>`
        },
        {
          name: 'Magnolia',
          first: 2,
          images: [
            require('@/assets/magnolia/0.jpg?vuetify-preload'),
            require('@/assets/magnolia/1.jpg?vuetify-preload'),
            require('@/assets/magnolia/2.jpg?vuetify-preload'),
            require('@/assets/magnolia/3.jpg?vuetify-preload'),
            require('@/assets/magnolia/4.jpg?vuetify-preload'),
          ],
          bp: `<svg class="blueprint" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1488 837">
              <path class="bpOther" d="M806.5,3.5v170h-585v660h810v-415h235V3.5Zm-567,348h-16v-118h16ZM521,831.5H423v-16h98Zm80.5-225a84.06,84.06,0,0,1-83-83h83Zm0-85h-85v2h0a86.06,86.06,0,0,0,85,85v205h-360v-355h360Zm13,85h-11v-83h11ZM791,831.5H693v-16h98Zm100,0H793v-16h98Zm0-18H793a98.6,98.6,0,0,1,98-98Zm360.5-410h-220v-210H1078v85h2v0a86.06,86.06,0,0,0,85-85h86.53ZM1163,193.5a84.06,84.06,0,0,1-83,83v-83Zm-83-2v-16h83v16Zm171.5-50a84.06,84.06,0,0,1-83-83h83Zm0-85h-85v2h0a86.06,86.06,0,0,0,85,85v30H923v20h93.5v620H893v-100h-2a100.6,100.6,0,0,0-100,100H616.5v-388h-15v18h-360v-250h360v132h15v-132H838v-20H821.5V23.5h430Zm13,85h-11v-83h11Z" />
              <g class="bpRoom">
                <rect id="magnolia_0" x="821.5" y="23.5" width="430" height="150" />
                <rect id="magnolia_1" x="1031.5" y="193.5" width="220" height="210" />
                <rect id="magnolia_2" x="616.5" y="193.5" width="400" height="620" />
                <rect id="magnolia_3" x="241.5" y="193.5" width="360" height="250" />
                <rect id="magnolia_4" x="241.5" y="459" width="360" height="355" />
              </g>
              <g class="bpWindow">
                <rect x="423" y="815.5" width="98" height="16" />
                <rect x="693" y="815.5" width="98" height="16" />
                <rect x="223.5" y="233.5" width="16" height="118" />
                <rect x="793" y="815.5" width="98" height="16" />
              </g>
              <rect class="bpEntrance" x="1253.5" y="58.5" width="11" height="83" />
              <g class="bpOther">
                <path d="M1120.62,279.69l24.69,24.69a3.75,3.75,0,0,0,5.31,0l1.76-1.76a3.75,3.75,0,0,0,0-5.31h0a17.56,17.56,0,0,0,.53-21.94,12,12,0,0,1,6.45-1.86,12.16,12.16,0,0,1,12.15,12.15V333.5h10V285.65a22.14,22.14,0,0,0-37.13-16.3,17.53,17.53,0,0,0-16.67,3.28h0a3.75,3.75,0,0,0-5.31,0l-1.76,1.76a3.75,3.75,0,0,0,0,5.31Zm.88,11.31a2.5,2.5,0,1,0,2.5-2.5A2.5,2.5,0,0,0,1121.5,291Zm-5,0a2.5,2.5,0,1,0-2.5,2.5A2.5,2.5,0,0,0,1116.5,291Zm-15,0a2.5,2.5,0,1,0,2.5-2.5A2.5,2.5,0,0,0,1101.5,291Zm25,5a2.5,2.5,0,1,0,2.5-2.5A2.5,2.5,0,0,0,1126.5,296Zm-7.5-2.5a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,1119,293.5Zm-12.5,2.5a2.5,2.5,0,1,0,2.5-2.5A2.5,2.5,0,0,0,1106.5,296Zm25,5a2.5,2.5,0,1,0,2.5-2.5A2.5,2.5,0,0,0,1131.5,301Zm-5,0a2.5,2.5,0,1,0-2.5,2.5A2.5,2.5,0,0,0,1126.5,301Zm-15,0a2.5,2.5,0,1,0,2.5-2.5A2.5,2.5,0,0,0,1111.5,301Zm20,5a2.5,2.5,0,1,0-2.5,2.5A2.5,2.5,0,0,0,1131.5,306Zm-15,0a2.5,2.5,0,1,0,2.5-2.5A2.5,2.5,0,0,0,1116.5,306Zm15,5a2.5,2.5,0,1,0,2.5-2.5A2.5,2.5,0,0,0,1131.5,311Zm-10,0a2.5,2.5,0,1,0,2.5-2.5A2.5,2.5,0,0,0,1121.5,311Zm5,5a2.5,2.5,0,1,0,2.5-2.5A2.5,2.5,0,0,0,1126.5,316Zm5,5a2.5,2.5,0,1,0,2.5-2.5A2.5,2.5,0,0,0,1131.5,321Z" />
                <path d="M859,463.5H774a7.5,7.5,0,0,0-7.5,7.5v50a7.5,7.5,0,0,0,7.5,7.5h38.3v5h-25a5,5,0,0,0,0,10h60a5,5,0,0,0,0-10h-25v-5H859a7.5,7.5,0,0,0,7.5-7.5V471A7.5,7.5,0,0,0,859,463.5Zm-2.5,55h-80v-45h80Z" />
                <path d="M471.92,642.61a4.58,4.58,0,0,1,4.58,4.58v26H458.17V660.94H384.83v12.23H366.5V604.42a4.59,4.59,0,0,1,4.58-4.59h9.17a4.59,4.59,0,0,1,4.58,4.59v38.19Zm-68.75-36.67a15.28,15.28,0,1,0,15.27,15.28A15.28,15.28,0,0,0,403.17,605.94Zm73.33,24.45a18.33,18.33,0,0,0-18.33-18.33H426.08a4.58,4.58,0,0,0-4.58,4.58V636.5h55Z" />
                <path d="M421.49,283.77c.11.68,2.32,13.63,2.32,18.58,0,7.54-4,12.92-9.94,15.08l1.86,34.34a3.47,3.47,0,0,1-3.46,3.65H403a3.47,3.47,0,0,1-3.46-3.65l1.86-34.34c-5.94-2.16-9.94-7.55-9.94-15.08,0-5,2.21-17.9,2.32-18.58.46-2.93,6.54-3,6.91.16v20.36c.19.49,2.18.46,2.31,0,.2-3.65,1.14-20.07,1.15-20.45.48-3,6.45-3,6.91,0,0,.39.95,16.8,1.15,20.45.13.46,2.14.49,2.31,0V283.93C414.94,280.81,421,280.84,421.49,283.77ZM438.68,325l-2.17,26.69a3.46,3.46,0,0,0,3.45,3.75H448A3.45,3.45,0,0,0,451.5,352V285a3.45,3.45,0,0,0-3.46-3.46c-11.9,0-31.93,25.74-9.36,43.4Z" />
              </g>
            </svg>`
        },
        {
          name: 'Butterfly',
          first: 2,
          images: [
            require('@/assets/butterfly/0.jpg?vuetify-preload'),
            require('@/assets/butterfly/1.jpg?vuetify-preload'),
            require('@/assets/butterfly/2.jpg?vuetify-preload'),
            require('@/assets/butterfly/3.jpg?vuetify-preload'),
            require('@/assets/butterfly/4.jpg?vuetify-preload'),
            require('@/assets/butterfly/5.jpg?vuetify-preload'),
            require('@/assets/butterfly/6.jpg?vuetify-preload'),
          ],
          bp: `<svg class="blueprint" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1488 837">
              <path class="bpOther" d="M172,89V400H527v14H447v1h0v.88h0A81.07,81.07,0,0,0,527,496V728H232V400H217V748H1316V89ZM496,746H298V730H496Zm31-252a79.07,79.07,0,0,1-78-78.09h78ZM1078,91h83v16h-83Zm0,18h83a84.06,84.06,0,0,1-83,83Zm-536,0H942V275H638a81.06,81.06,0,0,0-80.09-80v0H556v80H542Zm16,168h78v11H558Zm-.12-2V197A79.07,79.07,0,0,1,636,275ZM527,369.12H449A79.07,79.07,0,0,1,527,291ZM527,289a81.06,81.06,0,0,0-80,80.09h0V371h80v14H187V109H527Zm13,205H529V416h11Zm0-125H529V291h11ZM836,746H638V730H836Zm330,0H968V730h198Zm132-189V439h16V557Zm-2,2V728H542V290H942V428h354V559ZM1075,415v11H992V415Zm-83-2V330a84.06,84.06,0,0,1,83,83Zm306-98V197h16V315Zm-2,2v96H1077a86.05,86.05,0,0,0-85-85v0h-2v85H957V109h119v85h2v0a86.06,86.06,0,0,0,85-85h133V317Z" />
              <g class="bpRoom">
                <rect id="butterfly_6" x="232" y="400" width="295" height="328" />
                <rect id="butterfly_5" x="187" y="109" width="340" height="276" />
                <rect id="butterfly_4" x="542" y="109" width="400" height="166" />
                <rect id="butterfly_3" x="542" y="290" width="400" height="219" />
                <rect id="butterfly_2" x="542" y="509" width="400" height="219" />
                <rect id="butterfly_1" x="942" y="428" width="354" height="300" />
                <rect id="butterfly_0" x="957" y="109" width="339" height="304" />
              </g>
              <g class="bpWindow">
                <rect x="968" y="730" width="198" height="16" />
                <rect x="638" y="730" width="198" height="16" />
                <rect x="298" y="730" width="198" height="16" />
                <rect x="1298" y="439" width="16" height="118" />
                <rect x="1298" y="197" width="16" height="118" />
              </g>
              <rect class="bpEntrance" x="1078" y="91" width="83" height="16" />
              <g class="bpOther">
                <path d="M762.88,173.19l-24.69,24.69a3.75,3.75,0,0,1-5.31,0l-1.76-1.76a3.75,3.75,0,0,1,0-5.31h0a17.56,17.56,0,0,1-.53-21.94,12,12,0,0,0-6.45-1.86A12.16,12.16,0,0,0,712,179.15V227H702V179.15a22.14,22.14,0,0,1,37.13-16.3,17.53,17.53,0,0,1,16.67,3.28h0a3.75,3.75,0,0,1,5.31,0l1.76,1.76a3.75,3.75,0,0,1,0,5.31ZM762,184.5a2.5,2.5,0,1,1-2.5-2.5A2.5,2.5,0,0,1,762,184.5Zm5,0a2.5,2.5,0,1,1,2.5,2.5A2.5,2.5,0,0,1,767,184.5Zm15,0a2.5,2.5,0,1,1-2.5-2.5A2.5,2.5,0,0,1,782,184.5Zm-25,5a2.5,2.5,0,1,1-2.5-2.5A2.5,2.5,0,0,1,757,189.5Zm7.5-2.5a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,764.5,187Zm12.5,2.5a2.5,2.5,0,1,1-2.5-2.5A2.5,2.5,0,0,1,777,189.5Zm-25,5a2.5,2.5,0,1,1-2.5-2.5A2.5,2.5,0,0,1,752,194.5Zm5,0a2.5,2.5,0,1,1,2.5,2.5A2.5,2.5,0,0,1,757,194.5Zm15,0a2.5,2.5,0,1,1-2.5-2.5A2.5,2.5,0,0,1,772,194.5Zm-20,5a2.5,2.5,0,1,1,2.5,2.5A2.5,2.5,0,0,1,752,199.5Zm15,0a2.5,2.5,0,1,1-2.5-2.5A2.5,2.5,0,0,1,767,199.5Zm-15,5a2.5,2.5,0,1,1-2.5-2.5A2.5,2.5,0,0,1,752,204.5Zm10,0a2.5,2.5,0,1,1-2.5-2.5A2.5,2.5,0,0,1,762,204.5Zm-5,5a2.5,2.5,0,1,1-2.5-2.5A2.5,2.5,0,0,1,757,209.5Zm-5,5a2.5,2.5,0,1,1-2.5-2.5A2.5,2.5,0,0,1,752,214.5Z" />
                <path d="M784.5,468.5h-85A7.5,7.5,0,0,0,692,476v50a7.5,7.5,0,0,0,7.5,7.5h38.3v5h-25a5,5,0,0,0,0,10h60a5,5,0,0,0,0-10h-25v-5h36.7A7.5,7.5,0,0,0,792,526V476A7.5,7.5,0,0,0,784.5,468.5Zm-2.5,55H702v-45h80Z" />
                <path d="M407.42,253.11a4.58,4.58,0,0,1,4.58,4.58v26H393.67V271.44H320.33v12.23H302V214.92a4.59,4.59,0,0,1,4.58-4.59h9.17a4.59,4.59,0,0,1,4.58,4.59v38.19Zm-68.75-36.67a15.28,15.28,0,1,0,15.27,15.28A15.28,15.28,0,0,0,338.67,216.44ZM412,240.89a18.33,18.33,0,0,0-18.33-18.33H361.58a4.58,4.58,0,0,0-4.58,4.58V247h55Z" />
                <path d="M1119,542.77c.11.68,2.32,13.63,2.32,18.58,0,7.54-4,12.92-9.94,15.08l1.86,34.34a3.47,3.47,0,0,1-3.46,3.65h-9.23a3.47,3.47,0,0,1-3.46-3.65l1.86-34.34c-5.94-2.16-9.94-7.55-9.94-15.08,0-5,2.21-17.9,2.32-18.58.46-2.93,6.54-3,6.91.16v20.36c.19.49,2.18.46,2.31,0,.2-3.65,1.14-20.07,1.15-20.45.48-3,6.45-3,6.91,0,0,.39,1,16.8,1.15,20.45.13.46,2.14.49,2.31,0V542.93C1112.44,539.81,1118.52,539.84,1119,542.77ZM1136.18,584,1134,610.67a3.46,3.46,0,0,0,3.45,3.75h8.08A3.45,3.45,0,0,0,1149,611V544a3.45,3.45,0,0,0-3.46-3.46c-11.9,0-31.93,25.74-9.36,43.4Z" />
                <path d="M429.92,570.11a4.58,4.58,0,0,1,4.58,4.58v26H416.17V588.44H342.83v12.23H324.5V531.92a4.59,4.59,0,0,1,4.58-4.59h9.17a4.59,4.59,0,0,1,4.58,4.59v38.19Zm-68.75-36.67a15.28,15.28,0,1,0,15.27,15.28A15.28,15.28,0,0,0,361.17,533.44Zm73.33,24.45a18.33,18.33,0,0,0-18.33-18.33H384.08a4.58,4.58,0,0,0-4.58,4.58V564h55Z" />
              </g>
            </svg>`
        },
        {
          name: 'Orange',
          first: 4,
          images: [
            require('@/assets/orange/0.jpg?vuetify-preload'),
            require('@/assets/orange/1.jpg?vuetify-preload'),
            require('@/assets/orange/2.jpg?vuetify-preload'),
            require('@/assets/orange/3.jpg?vuetify-preload'),
            require('@/assets/orange/4.jpg?vuetify-preload'),
            require('@/assets/orange/5.jpg?vuetify-preload'),
            require('@/assets/orange/6.jpg?vuetify-preload'),
          ],
          bp: `<svg class="blueprint" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1488 1200">
              <path class="bpOther" d="M1365 60v425H515v320h50.938v315h-436.33V60H1365zm-20 255h-259.98l-.02-40H785v70h-15V80H150.392v1020h398.671V820H500V465h270v-30h15v30h560v-30.034c-37.29-.769-87.4-27.694-87.95-87.395h-.03V345H1345v-30zm18.01 117.931v-85.862h-15.95v85.862h15.95zM1345 347.069l-85.88.502c.55 58.527 49.89 84.779 86.34 85.334l-.46-85.836zm-73.39-34.121h66.78v-11.295h-66.78v11.295zm-115 0h66.78v-11.295h-66.78v11.295zM1223.39 300l-.39-66.565c-45.52.432-65.94 28.958-66.37 66.565h66.76zm116.61 0h5V80h-100v220h25.02c.43-38.519 21.36-67.742 67.98-68.174v-.02h2V300zm-115 0h5V80h-130v180h50v40h5.02c.43-38.519 21.36-67.742 67.98-68.174v-.02h2V300zm113.39 0l-.39-66.565c-45.52.432-65.94 28.958-66.37 66.565h66.76zm-336.76-27.002h76.32v-11.119h-76.32v11.119zm76.32-12.979l-.16-76.218c-45.52.494-75.64 39.426-76.14 76.45l76.3-.232zm1.84-.019h5.21V80H785v180h214.817c.623-37.846 31.453-77.545 77.973-78.038v-.023h2V260z" />
              <g class="bpRoom">
                <path id="orange_6" d="M150 817.803l400-.022v282.223H150V817.803z"/>
                <path id="orange_5" d="M150 817.803V465.002h350V817.78l-350 .022z"/>
                <path id="orange_4" d="M150 80h620v385H150z"/>
                <path id="orange_3" d="M785 80h300v180H785z"/>
                <path id="orange_2" d="M1100 80h130v220h-80v-40h-50V80z"/>
                <path id="orange_1" d="M1245 80h100v220h-100z"/>
                <path id="orange_0" d="M785 275h300v40h260v150H785V275z"/>
              </g>
              <path class="bpWindow" d="M1049.997 78h-70V62h70zM1199.997 78h-70V62h70zM1329.997 78h-70V62h70zM680 78H550V62h130zM148 260v130h-16V260zM148 500v130h-16V500zM148 770v130h-16V770zM290.001 1102h210.001v16h-210z" />
              <path class="bpEntrance" d="M1347 433.001v-86h16v86z" />
              <g class="bpOther">
                <path d="M1179 190.833a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0m5-5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0m5-5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0m-10 0a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0m15-5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0m-15 0a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0m20-5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0m-15 0a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0m-5 0a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0m25-5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0m-12.5-2.5a2.5 2.5 0 110 5 2.5 2.5 0 010-5m-7.5 2.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0m25-5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0m-15 0a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0m-5 0a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0m.885-11.313l-24.698 24.697a3.747 3.747 0 01-5.302 0l-1.77-1.768a3.75 3.75 0 010-5.303l.018-.018c-5.318-6.282-5.494-15.459-.529-21.934a12.063 12.063 0 00-6.454-1.861c-6.7 0-12.15 5.45-12.15 12.15v47.85h-10v-47.85c0-12.214 9.936-22.15 22.15-22.15 5.77 0 11.033 2.219 14.978 5.847 5.609-1.799 11.953-.703 16.667 3.286l.018-.017a3.747 3.747 0 015.302 0l1.77 1.768a3.75 3.75 0 010 5.303" />
                <path d="M390 287.5h-80v-45h80v45zm2.5-55h-85a7.501 7.501 0 00-7.5 7.5v50c0 4.141 3.359 7.5 7.5 7.5h38.297v5h-25c-2.766 0-5 2.234-5 5s2.234 5 5 5h60c2.765 0 5-2.234 5-5s-2.235-5-5-5h-25v-5H392.5c4.141 0 7.5-3.359 7.5-7.5v-50c0-4.141-3.359-7.5-7.5-7.5" />
                <path d="M652.178 278.977l-2.163 26.696a3.46 3.46 0 003.447 3.75h8.076a3.453 3.453 0 003.462-3.461v-66.923a3.462 3.462 0 00-3.462-3.461c-11.898 0-31.932 25.745-9.36 43.398v.001zm-17.192-41.207c.115.678 2.322 13.63 2.322 18.577 0 7.543-4.01 12.923-9.938 15.086l1.861 34.342a3.47 3.47 0 01-3.461 3.648h-9.232a3.47 3.47 0 01-3.461-3.648l1.861-34.342c-5.943-2.163-9.938-7.558-9.938-15.086 0-4.962 2.207-17.899 2.323-18.577.46-2.928 6.533-2.972 6.908.158v20.365c.187.492 2.177.462 2.307 0 .202-3.648 1.14-20.076 1.155-20.451.475-3 6.447-3 6.908 0 .029.389.952 16.803 1.154 20.451.13.462 2.135.492 2.308 0v-20.365c.375-3.115 6.462-3.086 6.923-.158" />
                <path d="M405 952.777c0-10.125-8.208-18.333-18.333-18.333h-32.084a4.583 4.583 0 00-4.583 4.583v19.861h55v-6.111zm-73.333-24.444c-8.438 0-15.278 6.84-15.278 15.277 0 8.438 6.84 15.278 15.278 15.278 8.437 0 15.277-6.84 15.277-15.278 0-8.437-6.84-15.277-15.277-15.277M400.417 965a4.583 4.583 0 014.583 4.583v25.972h-18.333v-12.222h-73.334v12.222H295v-68.75a4.583 4.583 0 014.583-4.583h9.167a4.583 4.583 0 014.583 4.583V965h87.084z" />
                <path d="M990 163.889c0-10.125-8.208-18.333-18.333-18.333h-32.084a4.583 4.583 0 00-4.583 4.583V170h55v-6.111zm-73.333-24.444c-8.438 0-15.278 6.84-15.278 15.278 0 8.437 6.84 15.277 15.278 15.277 8.437 0 15.277-6.84 15.277-15.277 0-8.438-6.84-15.278-15.277-15.278m68.75 36.667a4.583 4.583 0 014.583 4.583v25.972h-18.333v-12.222h-73.334v12.222H880v-68.75a4.584 4.584 0 014.583-4.584h9.167a4.584 4.584 0 014.583 4.584v38.195h87.084z" />
                <path d="M1317.917 139.583A2.09 2.09 0 001320 137.5v-2.084a2.09 2.09 0 00-2.083-2.083h-45.834a2.09 2.09 0 00-2.083 2.083v2.084a2.09 2.09 0 002.083 2.083h2.084v20.404c-2.63 1.315-4.167 2.89-4.167 4.596 0 8.75 4.505 16.432 11.302 20.898l-2.786 9.14c-.82 2.67 1.184 5.378 3.984 5.378h25c2.8 0 4.805-2.708 3.984-5.377l-2.786-9.14c6.797-4.467 11.302-12.15 11.302-20.9 0-1.705-1.537-3.28-4.167-4.595v-20.404h2.084zm-37.5 3.125c0-.573.468-1.042 1.041-1.042h6.25c.573 0 1.042.47 1.042 1.042v2.083c0 .573-.469 1.042-1.042 1.042h-6.25a1.045 1.045 0 01-1.041-1.042v-2.083zM1295 168.75c-10.04 0-18.177-1.862-18.177-4.167 0-2.305 8.138-4.167 18.177-4.167s18.177 1.862 18.177 4.167c0 2.305-8.138 4.167-18.177 4.167z" />
              </g>
            </svg>`
        }
      ]
    }
  }
}
</script>

<style lang="stylus">

.v-tabs-bar
  z-index 1
  box-shadow 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14)

</style>

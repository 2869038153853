<template>

  <v-container>
    <v-row>

      <v-col cols="12" sm="6" lg="4" v-for="(place, i) in places" :key="i">
        <v-card>
          <v-img
            :src="place.photo"
            height="200px"
          >
          </v-img>

          <v-card-title class="pb-0">
            <!-- <div> -->
              <h3 class="headline">{{ place.title }}</h3>
              <!-- <div>Located two hours south of Sydney in the <br>Southern Highlands of New South Wales, ...</div> -->
            <!-- </div> -->
          </v-card-title>

          <v-card-actions>
            <v-btn text color="primary" :href="place.link" target="_blank">Explore</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

    </v-row>
  </v-container>

</template>

<script>
export default {
  data () {
    return {
      places: [
        {
          title: 'Goldeck',
          link: 'https://sportberg-goldeck.com',
          photo: require('@/assets/surroundings/goldeck.jpg?vuetify-preload')
        },
        {
          title: 'Badehaus',
          link: 'https://www.badehaus-millstaettersee.at',
          photo: require('@/assets/surroundings/badehaus.jpg?vuetify-preload')
        },
        {
          title: 'Malta',
          link: 'https://www.verbund.com/de-at/ueber-verbund/besucherzentren/malta',
          photo: require('@/assets/surroundings/malta.jpg?vuetify-preload')
        },
        {
          title: 'Bergbahnen Turracher Höhe',
          link: 'https://www.turracherhoehe.at/de/sommer/seilbahnbetrieb',
          photo: require('@/assets/surroundings/bergbahnen.jpg?vuetify-preload')
        },
        {
          title: 'Affenberg',
          link: 'https://www.affenberg.com',
          photo: require('@/assets/surroundings/affenberg.jpg?vuetify-preload')
        },
        {
          title: 'Mölltaler Gletscher',
          link: 'http://www.gletscher.co.at',
          photo: require('@/assets/surroundings/gletscher.jpg?vuetify-preload')
        },
        {
          title: 'Rosegg',
          link: 'http://www.rosegg.at',
          photo: require('@/assets/surroundings/rosegg.jpg?vuetify-preload')
        },
        {
          title: 'Raggaschlucht',
          link: 'http://www.flattach.at/de/articles/view/2357',
          photo: require('@/assets/surroundings/raggaschlucht.jpg?vuetify-preload')
        },
        {
          title: 'Pyramidenkogel',
          link: 'http://www.pyramidenkogel.info',
          photo: require('@/assets/surroundings/pyramidenkogel.jpg?vuetify-preload')
        }
      ]
    }
  }
}
</script>

<style lang='stylus'>



</style>

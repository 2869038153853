<template>
  <div>

    <v-carousel hide-delimiters prev-icon="" next-icon="">
      <v-carousel-item v-for="(cS,i) in carouselSources" :key="i" :src="cS"></v-carousel-item>
    </v-carousel>

    <v-container id="testimonialContainer" style="height: 180px">
      <v-slide-y-transition>
        <v-card color="white" v-show="showTestimonial">
          <v-card-text>
            <div class="text-h6 font-italic text-center grey--text text--darken-4">“{{ currentTestimonial }}”</div>
          </v-card-text>
        </v-card>
      </v-slide-y-transition>
    </v-container>

    <v-container>
      <v-row>
        <v-col cols="6" md="4" lg="2" class="icon-block text-center" v-for="(_, i) in $t('info').length" :key="i">
          <v-icon x-large :class="`${infoIcons[i].color}--text`">{{ infoIcons[i].icon }}</v-icon>
          <h2 class="headline">{{ $t('info[' + i + '].title') }}</h2>
          <p>{{ $t('info[' + i + '].description') }}</p>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import router from '../router'
import { mdiWifi, mdiFlower, mdiParking, mdiCashMultiple, mdiPawOff, mdiSmokingOff } from '@mdi/js'

export default {
  mounted: function() {
    const T = this
    const len = router.app.$t('testimonials').length

    let count = 0

    let last = Date.now()
    function animate() {
      const now = Date.now()
      if (now - last > 6000) {
        last = now
        T.showTestimonial = false
        setTimeout(function() {
          T.currentTestimonial = router.app.$t('testimonials[' + (++count % len) + ']')
          T.showTestimonial = true
        }, 800)
      }
      requestAnimationFrame(animate)
    }
    requestAnimationFrame(animate)
  },
  data() {
    return {
      currentTestimonial: this.$t('testimonials[0]'),
      showTestimonial: true,
      carouselSources: [
        require('@/assets/main.jpg?vuetify-preload'),
        // require('@/assets/right.jpg?vuetify-preload'),
        // require('@/assets/left.jpg?vuetify-preload')
      ],
      infoIcons: [
        {
          icon: mdiWifi,
          color: "green"
        },
        {
          icon: mdiFlower,
          color: "green"
        },
        {
          icon: mdiParking,
          color: "blue"
        },
        {
          icon: mdiCashMultiple,
          color: "yellow"
        },
        {
          icon: mdiPawOff,
          color: "red"
        },
        {
          icon: mdiSmokingOff,
          color: "red"
        }
      ]
    }
  }
}
</script>

<style lang="stylus">

.v-carousel
  z-index 1

#testimonialContainer
  padding 0 24px 24px 24px
  .v-card
    border-radius 0 0 4px 4px

.icon-block
  svg
    margin-bottom 8px
  h2, p
    margin-bottom 8px
    overflow-wrap break-word

</style>

import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Apartments from './views/Apartments.vue'
import Surroundings from './views/Surroundings.vue'
import Contact from './views/Contact.vue'
import NotFound from './views/NotFound.vue'
import i18n from './i18n'

Vue.use(Router)

const appendToTitle = ' - Pension Hexagon'

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      nameT: 'nav.home',
      component: Home,
      meta: {
        titleT: 'nav.home'
      }
    },
    {
      path: '/apartments',
      nameT: 'nav.apartments',
      component: Apartments,
      meta: {
        titleT: 'nav.apartments'
      }
    },
    {
      path: '/surroundings',
      nameT: 'nav.surroundings',
      component: Surroundings,
      meta: {
        titleT: 'nav.surroundings'
      }
    },
    {
      path: '/contact',
      nameT: 'nav.contact',
      component: Contact,
      meta: {
        titleT: 'nav.contact'
      }
    },
    {
      path: '*',
      component: NotFound,
      displayOnNav: false,
      meta: {
        title: '404 Not Found',
        metaTags: [
          {
            name: 'robots',
            content: 'noindex,nofollow'
          }
        ]
      }
    }
  ]
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && (r.meta.title || r.meta.titleT))

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = (nearestWithTitle.meta.title ? nearestWithTitle.meta.title : i18n.t(nearestWithTitle.meta.titleT)) + appendToTitle

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag))

  next()
})

export default router
